import * as process from 'process';

export const BRAND_SAAS_DOMAIN_URL = process.env.NEXT_PUBLIC_BRAND_SAAS_DOMAIN_URL || 'https://brand.saas.passio.eco';

export const BRAND_SAAS_EXPIRED_TOKEN = '30';

export const PASSIO_MAIN_DOMAIN = process.env.NEXT_PUBLIC_PASSIO_MAIN_DOMAIN || 'passio.eco';

export const KEY_TOKEN = process.env.NEXT_PUBLIC_BRAND_KEY_TOKEN || 'brand_saas_access_token';

export const BRAND_SAAS_API_VERSION = 'v1';

export const BRAND_SAAS_API_VERSION_V2 = 'v2';

export const IP_FIND_TOKEN = '86f5f280-f4eb-11ec-8676-4f4388bc6daa';

export const IP_URL_ENDPOINT = 'https://ipinfo.io/';

export const TYPE_BRAND = process.env.NEXT_PUBLIC_BRAND_KEY_TYPE_BRAND || 'type_brand';

export const PASSIO_DOMAIN = process.env.NEXT_PUBLIC_PASSIO_DOMAIN_URL || 'https://account.passio.eco';

export const PASSIO_API_URL = process.env.NEXT_PUBLIC_PASSIO_API_URL || 'https://ga.passio.eco';
export const TIKTOK_AFFILIATE_DOMAIN_URL =
  process.env.NEXT_PUBLIC_TIKTOK_AFFILIATE_DOMAIN_URL || 'https://tools.shop4.live';
export const LOCALE_BRAND = 'brand_saas_locale_brand';
export const LOCALE_HOST = 'brand_saas_locale_host';
export const LOCALE_AGENCY = 'brand_saas_locale_agency';
export const HOT_FEATURES = ['dashboard', 'live', 'order', 'kol-live'];
export const NEW_FEATURES = [' '];
export const BRAND_SAAS_SECRET_KEY_TOKEN = process.env.NEXT_PUBLIC_SECRET_KEY_TOKEN || '7ad85668b460dca205d0d2625eee74c0';
export const BRAND_SAAS_HOME_PAGE_ADMIN = process.env.NEXT_PUBLIC_HOME_PAGE_ADMIN || 'https://brand.saas.passio.eco/';
export const SHOP_CODE_HASAKI = 'VNLC2HWLQL';
export const DEPLOYMENT_ID_GOOGLE_SHEET =
  process.env.NEXT_PUBLIC_DEPLOYMENT_ID_GOOGLE_SHEET ||
  'AKfycbxr2SHsWIILgQAdajMHOKjoRNs4kX47y18LpEwE03pe4_znRGg_1aF60Z82aSJTL6sevA';
