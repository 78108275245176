import { BRAND_SAAS_API_VERSION, BRAND_SAAS_DOMAIN_URL, TIKTOK_AFFILIATE_DOMAIN_URL } from '@constants/system';
import { Requests } from '@config/request';
import { IAppResponseDTO, IListResponseDTOV2 } from '@constants/responseDTO';
import {
  ICategories,
  ICreatorContact,
  IDataInfo,
  IDataLiveActive,
  IDataLiveMonitor,
  IDataShopActive,
  IDeliveryUnit,
  IFilterReport,
  IListContentInTAP,
  IManageConnectShop,
  InfoCampaign,
  IOderLive,
  IPerformanceTAP,
  IPostProduct,
  IReportData,
  IReportPerformance,
  ISkuInfo,
  ISkuInTAP,
  ITAPCampaign,
  ITopProduct,
  PSuggested,
  STATUS_SUGGEST,
} from '@features/tiktok-affiliate/tiktokAffiliateInterface';
import { PaginationResponse } from '@features/brands-booking/brandsBookingInterface';
import { IOverviewInterface, IReportCreatorConnect } from '@features/overview/overviewInterface';

const URL_PATH = {
  post_connect_tiktok_shop: `${BRAND_SAAS_DOMAIN_URL}/api/${BRAND_SAAS_API_VERSION}/brand-booking/tiktok-affiliate/connect-shop`,
  post_disconnect_tiktok_shop: `${BRAND_SAAS_DOMAIN_URL}/api/${BRAND_SAAS_API_VERSION}/brand-booking/tiktok-affiliate/disconnect-shop`,
  get_info_tiktok_shop: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/check-shop-code`,
  check_connect_tiktok_shop: `${BRAND_SAAS_DOMAIN_URL}/api/${BRAND_SAAS_API_VERSION}/brand-booking/tiktok-affiliate/get-shop-connection`,
  get_list_tap_campaign: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/get-campaigns`,
  get_list_product_suggested: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/get-approved-products`,
  get_list_product_filter: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/get-basic-products`,
  edit_product_sample: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/save-product`,
  get_list_shop_active: `${BRAND_SAAS_DOMAIN_URL}/api/tap/livestream/list-shop-code`,
  get_list_live_active: `${BRAND_SAAS_DOMAIN_URL}/api/tap/livestream/list-live-active`,
  get_overview_live_monitor: `${BRAND_SAAS_DOMAIN_URL}/api/tap/livestream/monitor`,
  get_detail_live_active: `${BRAND_SAAS_DOMAIN_URL}/api/tap/livestream/monitor/`,
  get_list_orders_live: `${BRAND_SAAS_DOMAIN_URL}/api/tap/livestream/orders`,
  get_top_live_product: `${BRAND_SAAS_DOMAIN_URL}/api/tap/livestream/top-product`,
  get_list_connect_shop: `${BRAND_SAAS_DOMAIN_URL}/api/${BRAND_SAAS_API_VERSION}/brand-booking/tiktok-affiliate/list-shop-connected`,
  get_filter_order_report: `${BRAND_SAAS_DOMAIN_URL}/api/${BRAND_SAAS_API_VERSION}/brand-booking/tap/get-filter`,
  get_order_report: `${BRAND_SAAS_DOMAIN_URL}/api/${BRAND_SAAS_API_VERSION}/brand-booking/tap`,
  get_list_campaign: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/get-campaigns-by-shop-code`,
  get_report_performance: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/report-performance`,
  get_performance_tap: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/get-performances`,
  get_info_sku: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/campaign/product/detail`,
  get_list_creator_contact: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/campaign/product/list-creators`,
  get_list_post_product: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/campaign/product/creator/list-posts`,
  get_list_sku_in_campaign: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/get-products`,
  get_list_content: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/list-contents`,
  update_suggest_product_status: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/save-request-product-status`,
  get_list_category: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/categories`,
  get_status_crawl_order: `${BRAND_SAAS_DOMAIN_URL}/api/${BRAND_SAAS_API_VERSION}/brand-booking/tap/check-sync-order`,
  post_crawl_order: `${BRAND_SAAS_DOMAIN_URL}/api/${BRAND_SAAS_API_VERSION}/brand-booking/tap/sync-order`,
  get_overview_creator_connect: `${BRAND_SAAS_DOMAIN_URL}/api/${BRAND_SAAS_API_VERSION}/brand-booking/connect-creator/overview`,
  get_report_creator_connect: `${BRAND_SAAS_DOMAIN_URL}/api/${BRAND_SAAS_API_VERSION}/brand-booking/connect-creator`,
  get_delivery_units: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/list-shipping-units`,
  update_shipping_info: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/save-shipping-info`,
  request_ads_code: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/request-ads-code`,
  save_info_content: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/save-info-content`,
  get_list_shop_active_by_time: `${BRAND_SAAS_DOMAIN_URL}/api/${BRAND_SAAS_API_VERSION}/brand-booking/connect-creator/get-list-shop-code`,
  import_shipping_info: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/import-shipping`,
  import_shipping_status: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/import-shipping-status`,
  export_data: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/download-template-shipping`,
  export_error_data: `${TIKTOK_AFFILIATE_DOMAIN_URL}/api/brand-saas/tiktok-affiliate/export-error-shipping`,
};

const tiktokAffiliateApis = {
  connectTiktokShop: async (params: { shop_code: string }): Promise<IAppResponseDTO<any>> => {
    return await Requests.post(URL_PATH.post_connect_tiktok_shop, params);
  },
  disconnectTiktokShop: async (params: { shop_code: string }): Promise<IAppResponseDTO<any>> => {
    return await Requests.post(URL_PATH.post_disconnect_tiktok_shop, params);
  },
  getListTAPCampaign: async (params: any): Promise<IListResponseDTOV2<ITAPCampaign>> => {
    const res = await Requests.get(URL_PATH.get_list_tap_campaign, params);
    return res.data;
  },
  getInfoShop: async (params: any): Promise<IDataInfo> => {
    const res = await Requests.get(URL_PATH.get_info_tiktok_shop, params);
    return res.data;
  },
  getListProductSuggested: async (params: any): Promise<IListResponseDTOV2<PSuggested>> => {
    const res = await Requests.get(URL_PATH.get_list_product_suggested, params);
    return res.data;
  },
  getListFilterProduct: async (params: any): Promise<IListResponseDTOV2<PSuggested>> => {
    const res = await Requests.get(URL_PATH.get_list_product_filter, params);
    return res.data;
  },
  updateProductSample: async (params: any): Promise<IAppResponseDTO<any>> => {
    return await Requests.post(URL_PATH.edit_product_sample, params);
  },
  getListOrdersLive: async (params: any) => {
    return await Requests.getAxios<any, PaginationResponse<IOderLive>>(URL_PATH.get_list_orders_live, params);
  },
  getTopLiveProduct: async (params: any): Promise<IAppResponseDTO<ITopProduct[]>> => {
    return await Requests.get(URL_PATH.get_top_live_product, params);
  },
  getListShopActive: async (): Promise<IAppResponseDTO<IDataShopActive[]>> => {
    return await Requests.get(URL_PATH.get_list_shop_active);
  },
  getListLiveActive: async (params: any): Promise<IAppResponseDTO<IDataLiveActive[]>> => {
    return await Requests.get(URL_PATH.get_list_live_active, params);
  },
  getLiveMonitorOverview: async (params: any): Promise<IDataLiveMonitor> => {
    const res = await Requests.get(URL_PATH.get_overview_live_monitor + '/overview', params);
    return res.data;
  },
  getDetailLiveActive: async (params: any): Promise<IAppResponseDTO<IDataLiveActive>> => {
    const res = await Requests.get(URL_PATH.get_overview_live_monitor + params.live_id + '/info', {
      shop_code: params.shop_code,
    });
    return res;
  },
  getListConnectShop: async (): Promise<IAppResponseDTO<IManageConnectShop[]>> => {
    const res = await Requests.get(URL_PATH.get_list_connect_shop);
    return res;
  },
  getFilterReport: async (params: any): Promise<IAppResponseDTO<IFilterReport>> => {
    return await Requests.get(URL_PATH.get_filter_order_report, params);
  },
  getOrderReport: async (params: any) => {
    return await Requests.getAxios<any, PaginationResponse<IReportData>>(URL_PATH.get_order_report, params);
  },
  getListCampaign: async (params: any): Promise<IAppResponseDTO<Array<InfoCampaign>>> => {
    return await Requests.post(URL_PATH.get_list_campaign, params);
  },
  getReportPerformance: async (params: any): Promise<IAppResponseDTO<IReportPerformance>> => {
    return await Requests.get(URL_PATH.get_report_performance, params);
  },
  getPerformanceTAP: async (params: any): Promise<IListResponseDTOV2<IPerformanceTAP>> => {
    const res = await Requests.get(URL_PATH.get_performance_tap, params);
    return res.data;
  },
  getInfoSKU: async (params: any): Promise<IAppResponseDTO<ISkuInfo>> => {
    return await Requests.get(URL_PATH.get_info_sku, params);
  },
  getListCreatorContact: async (params: any): Promise<IListResponseDTOV2<ICreatorContact>> => {
    const res = await Requests.get(URL_PATH.get_list_creator_contact, params);
    return res.data;
  },
  getListPostProduct: async (params: any): Promise<IListResponseDTOV2<IPostProduct>> => {
    const res = await Requests.get(URL_PATH.get_list_post_product, params);
    return res.data;
  },
  getListSKUCampaign: async (params: any): Promise<IListResponseDTOV2<ISkuInTAP>> => {
    const res = await Requests.get(URL_PATH.get_list_sku_in_campaign, params);
    return res.data;
  },
  getListContent: async (params: any): Promise<IListResponseDTOV2<IListContentInTAP>> => {
    const res = await Requests.get(URL_PATH.get_list_content, params);
    return res.data;
  },
  updateStatusSuggest: async (params: {
    list_requests: Array<{
      request_id: string | undefined;
      status: STATUS_SUGGEST;
      reason_reject?: string;
    }>;
    shop_code: string;
  }) => {
    return await Requests.post(URL_PATH.update_suggest_product_status, params);
  },
  getListCategories: async (params: any): Promise<Array<ICategories>> => {
    const res = await Requests.get(URL_PATH.get_list_category, params);
    return res.data;
  },
  getStatusCrawlOrder: async (): Promise<IAppResponseDTO<{ is_processing: boolean }>> => {
    return await Requests.get(URL_PATH.get_status_crawl_order);
  },
  crawlOrderTAP: async (payload?: { shop_code: Array<string> }) => {
    return await Requests.post(URL_PATH.post_crawl_order, payload);
  },
  getOverviewCreatorConnect: async (params: any): Promise<IAppResponseDTO<IOverviewInterface>> => {
    return await Requests.get(URL_PATH.get_overview_creator_connect, params);
  },
  getReportCreatorConnect: async (params: any) => {
    return await Requests.getAxios<any, PaginationResponse<IReportCreatorConnect>>(
      URL_PATH.get_report_creator_connect,
      params,
    );
  },
  getListDeliveryUnits: async (params: any): Promise<Array<IDeliveryUnit>> => {
    const res = await Requests.get(URL_PATH.get_delivery_units, params);
    return res.data;
  },
  updateShippingInfo: async (params: {
    shop_code: string;
    request_id: string;
    tracking_code: string | null;
    carrier: string | null | number;
    note?: string | null;
  }) => {
    return await Requests.post(URL_PATH.update_shipping_info, params);
  },
  requestAdsCode: async (params: any) => {
    return await Requests.post(URL_PATH.request_ads_code, params);
  },
  saveInfoContent: async (params: any) => {
    return await Requests.post(URL_PATH.save_info_content, params);
  },
  getListShopActiveByTime: async (params: any) => {
    const res = await Requests.getAxios<any, PaginationResponse<IManageConnectShop>>(
      URL_PATH.get_list_shop_active_by_time,
      params,
    );
    return res?.data},
  importShippingInfo: async (payload: any) => {
    return await Requests.postFormData(URL_PATH.import_shipping_info, payload);
  },
  importShippingStatus: async (params: any) => {
    return await Requests.get(URL_PATH.import_shipping_status, params);
  },
  exportData: async (params: any) => {
    return await Requests.getAxiosBlob(URL_PATH.export_data, params);
  },
  exportErrorData: async (params: any) => {
    return await Requests.getAxiosBlob(URL_PATH.export_error_data, params);
  },

};
export default tiktokAffiliateApis;
